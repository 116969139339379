//Always use strict mode. don't be sloppy.
//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Strict_mode
'use strict';

// Wrap everything in an Immediately Invoked Function Expression to avoid conflicts of the $ function. e.g. when using both jQuery and Bootstrap
//https://codeburst.io/javascript-what-the-heck-is-an-immediately-invoked-function-expression-a0ed32b66c18
(function ($) {

  $(window).scrollTop($("#ScrollPosition").val());
   

  $(window).on("scroll", function () {
    $("#ScrollPosition").val(window.scrollY);

    if (window.scrollY > 50) {
      $("#top-nav").addClass("fixed-top nav-scrolling");
      var navbar_height = $('#top-nav').outerHeight();
      document.body.style.paddingTop = navbar_height + 'px';
    }
    else {
      $("#top-nav").removeClass("fixed-top nav-scrolling");
      document.body.style.paddingTop = '0';
    }
  });

  $(".link-about").on('click', function () {
    var position = $("#about").offset().top;

    $("HTML, BODY").animate({
      scrollTop: position
    }, 1000);
  });

  $(".link-services").on('click', function () {
    var position = $("#services").offset().top;

    $("HTML, BODY").animate({
      scrollTop: position
    }, 1000);
  });

  $(".link-contact").on('click', function () {
    var position = $("#contact").offset().top;

    $("HTML, BODY").animate({
      scrollTop: position
    }, 1000);
  });

}(jQuery));